@import "_variables.css";

form {

}


input {
  box-sizing: border-box;
  font-size: 2rem;
  margin-bottom: 10px;
  border-radius: var(--form-input-border-radius);
  border: 1px solid black;
  width: 100%;
  padding: 2px;
}

input[type=checkbox] {
  width: auto;
}

input[type=button] {

}

textarea {
  box-sizing: border-box;
  margin-bottom: 10px;
  border-radius: var(--form-input-border-radius);
  width: 100%;
}

