@import "_variables.css";

.header {
  display: flex;
  flex-direction: column;
  /*width: 100vw;*/
  background-color: var(--header-background-color);
  text-align: center;
  padding: 20px;
  height: 8rem;

  .header-title {
    font-size: 2.5rem;
    color: var(--heading-primary-color);
    margin: 0;
    padding: 0;
  }

  .tagline {
    font-size: 1.5rem;

  }

  .login-info {
    font-size: 1.2rem;
    color: var(--heading-primary-color);
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: var(--heading-primary-color);
  }

}







