:root {
  --breakpoint: 56rem;

  --body-color: black;
  --body-background-color: rgba(193, 215, 191, 0.44);

  --primary-font-family: 'Montserrat', sans-serif;

  --default-border-radius: 5px;
  --heading-primary-color: black;
  --link-hover-color: green;
  --active-link-color: green;

  --sidebar-background-color: #979797;

  --footer-background-color: rgba(98, 159, 96, 0.44);
  --header-background-color: rgba(33, 120, 25, 0.44);

  --article-background-color: #c1d7bf;

  --nav-background: #c1d7bf;
  --nav-active-color: yellow;
  --nav-hover-background-color: #a7baa5;

  --form-input-border-radius: 0.5rem;
  --form-input-line-height: 2rem;
  --form-input-button-hover-background-color-hover: #8cd28c;
  --form-input-button-hover-background-color-active: #79b579

}

