@import "_variables.css";

footer {
  display: flex;
  position: fixed;
  flex-direction: column;
  width: 100%;
  bottom: 0;
  background-color: var(--footer-background-color);
  -webkit-backdrop-filter: blur(1.5rem);
  backdrop-filter: blur(1.5rem) !important;

}

footer ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

footer ul li {
  list-style-type: none;
  padding: .1rem;
}

footer ul li a {
  font-size: 1.2rem;

}

.contact-icons {
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

}
.footer-group {
  justify-content: center;
  align-content: center;
  display: flex;

}

.footer-group  {
  padding: .5rem;
}

.footer-group div {
  padding: .5rem;
}



.footer-title {
  font-size: 2rem;
  text-align: center;
}

.footer-last {
  line-height: 2rem;
}

.footer-last div {
  width: 20rem;
  text-align: center;
}

.copyright {
  font-size: 1.2rem;
}