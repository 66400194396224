@import "_variables.css";

article {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 1em;
  border: 0.1rem solid var(--article-background-color);
  overflow: hidden;
}

article header {
  font-size: 1.6rem;
  border: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--article-background-color);
  padding: 1rem;
}

article header {
  justify-content: center;
  text-align: center;
}

article header .dated {
  display: flex;
  justify-content: space-between;
}

article .article-content {
  display: flex;
  padding: 1.8rem;
  flex-direction: column;
}

.article-content a {
  font-size: 1.8rem;

}

.article-content ul li {
  padding: 0.5rem;
}



@media only screen and (max-width: 56rem) {


}
