@import "../styles/_variables.css";

nav {
  display: flex;
  justify-content: space-evenly;
  background-color: color-mix(in srgb,var(--header-background-color), green 20%);
  width: 100%;
}


nav ul {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  padding: 0.8rem;
  list-style: none;
  flex-wrap: wrap;
  width: 100vw;
}

nav ul li {
  box-sizing: content-box;
}

nav .menu-button {
  display:flex;
  background-color: var(--header-background-color);
  border-radius: 0.5em;
  padding:  0.5rem;
  width: 15rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  transition: background-color 0.5s, scale 0.2s ease-in-out, content 3s ease-in-out;
}

.menu-button a {

  text-decoration: none;
  color: white;
  font-size: 2em;
  line-height: 2em;
  margin: 0;
  padding: 0;

  height: 100%;
  width: 100%;
  :visited {
    color: white;
  }
}

nav .menu-button:hover {
  scale: 1.3;
  background-color: green;
}

.menu-button .active:before {
  content : "[ ";
}

.menu-button .active:after {
  content : " ]";
}

.menu-link {
  text-decoration: none;
}

@media only screen and (max-width: 56rem) {

  nav .menu-button {
    margin: 0.6rem;
  }

}







