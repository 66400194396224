@font-face {
    font-family: "Montserrat";
    src: url("../../static/fonts/Montserrat-VariableFont_wght.ttf");
}

@font-face {
    font-family: "OpenSans";
    src: url("../../static/fonts/OpenSans-VariableFont_wdth,wght.ttf");
}

@font-face {
    font-family: "OpenSans Italic";
    src: url("../../static/fonts/OpenSans-Italic-VariableFont_wdth,wght.ttf");
}
