@import "fonts.css";
@import "_variables.css";
@import "reset.css";
@import "header.css";
@import "nav.css";
@import "footer.css";
@import "forms.css";
@import "post.css";
@import "article.css";

/* add font from static/fonts folder */

html {
    font-family: "OpenSans", sans-serif;
    font-weight: normal;
}


.container {
    display: flex;
    flex-direction: column;

    color: var(--body-color);
}

#sub-content {
    padding-left: 10rem;
    padding-right: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


#sub-content .sub-content-wrapper {
    width: 75%;
}

#sub-content::before {
    content: "";
    height: 3rem;
}

#page-content {
    display:flex;
    flex-direction: column;

}

p {
    font-weight: lighter;
    padding: 0;
}


#page-content::after {
    content: "";
    height: 20rem;
}

.p-text p {
    font-size: 2rem;
    font-weight: lighter;
    padding: 1rem;
}

.p-text h1 {
    font-size: 3rem;
    font-weight: bold;
    padding: 1rem;
}

.link-ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
}

@media only screen and (max-width:56rem) {

    #sub-content {
        padding-left: 0;
        padding-right: 0;
    }

    #sub-content .sub-content-wrapper {
        width: 100%;
    }
}




