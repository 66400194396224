@import "_variables.css";


html {
    font-size: 62.5%;
    font-style: normal;
    font-weight: normal;
    width: 100%;
}

body, h1, p, article, header {
    margin: 0;
    padding: 0;

}

p {
    font-size: 1.5rem;
}

